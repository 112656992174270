import adminGuard from '@/router/guards/admin-guard'
import multiGuard from '@/router/utils/vue-router-multiguard'
import authGuard from '@/router/guards/auth-guard'
import checkTokenGuard from '@/router/guards/check-token-guard'
import ADMIN_PERMISSIONS from '@/constants/admin-permissions'
import checkAdminPermissionsGuard from '@/router/guards/check-admin-permissions-guard'

const adminRoutes = [
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "super-admin" */ '@/layouts/full/LayoutFull.vue'),
    beforeEnter: multiGuard([checkTokenGuard, authGuard, adminGuard, checkAdminPermissionsGuard]),
    children: [
      {
        path: '/',
        name: 'admin:home',
        meta: { adminPermissions: [ADMIN_PERMISSIONS.SUPER_ADMIN] },
        component: () => import(/* webpackChunkName: "superadmin" */ '@/views/admin/statistics/Statistics.vue'),
      },
      {
        path: 'accounts',
        name: 'admin:accounts',
        meta: { adminPermissions: [ADMIN_PERMISSIONS.SUPER_ADMIN] },
        component: () => import(/* webpackChunkName: "superadmin" */ '@/views/admin/users/UsersTable.vue'),
      },
      {
        path: 'accounts/process/:id?',
        name: 'admin:accounts-add',
        meta: { adminPermissions: [ADMIN_PERMISSIONS.SUPER_ADMIN] },
        component: () => import(/* webpackChunkName: "superadmin" */ '@/views/admin/users/AccountForm.vue'),
      },
      {
        path: 'business-types',
        name: 'admin:business-types',
        meta: { adminPermissions: [ADMIN_PERMISSIONS.SUPER_ADMIN] },
        component: () => import(/* webpackChunkName: "superadmin" */ '@/views/admin/business-types/BusinessTypesTable.vue'),
      },
      {
        path: 'receipts',
        name: 'admin:receipts',
        meta: { adminPermissions: [ADMIN_PERMISSIONS.SUPER_ADMIN] },
        component: () => import(/* webpackChunkName: "superadmin" */ '@/views/admin/invoices/InvoicesTable.vue'),
      },
      {
        path: 'plans',
        name: 'admin:plans',
        meta: { adminPermissions: [ADMIN_PERMISSIONS.SUPER_ADMIN] },
        component: () => import(/* webpackChunkName: "superadmin" */ '@/views/admin/plans/PlansView.vue'),
      },
      {
        path: 'help-categories',
        name: 'admin:help-categories',
        meta: { adminPermissions: [ADMIN_PERMISSIONS.GUIDE] },
        component: () => import(/* webpackChunkName: "superadmin" */ '@/views/admin/help/HelpQuestionsTable.vue'),
      },
      {
        path: 'help-sections',
        name: 'admin:help-sections',
        meta: { adminPermissions: [ADMIN_PERMISSIONS.GUIDE] },
        component: () => import(/* webpackChunkName: "superadmin" */ '@/views/admin/help/HelpSectionsTable.vue'),
      },
    ],
  },
]

export default adminRoutes
