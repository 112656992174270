const items = [
  {
    header: 'menu.account-management',
  },
  /* {
    title: 'menu.help',
    route: 'owner:help',
    icon: 'HelpCircleIcon',
  }, */
  {
    title: 'invoice.invoices',
    icon: 'DollarSignIcon',
    route: 'owner:invoices',
  },
]
export default items.map(item => ({ ...item, shared: true }))
