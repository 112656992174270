import $http from '@/libs/axios'
import FormsService from '@/services/forms-service'

class PrintAPIs {
  static async process(payload: any) {
    const formData = FormsService.convertObjectToFormData(payload)
    const config = { headers: FormsService.MULTIPART_HEADER }
    return (await $http.post('print-settings', formData, config)).data
  }

  static async show() {
    return (await $http.get('print-settings')).data
  }
}

export default PrintAPIs
