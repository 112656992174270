import DeliveryZonesAPIs from '@/api/store/delivery-zone'

export default {
  namespaced: true,
  state: {
    zones: [],
    zonesFetched: false,
  },
  getters: {
    zones: (state: any) => state.zones ?? [],
    zonesFetched: (state: any) => state.zonesFetched ?? false,
  },
  mutations: {
    SET_ZONES(state: any, zones: any) {
      state.zones = zones
      state.zonesFetched = true
    },
  },
  actions: {
    async FETCH_ZONES(context: any) {
      if (context.state.zonesFetched) return
      // eslint-disable-next-line consistent-return,no-return-await
      await DeliveryZonesAPIs.list(null, true).then(response => {
        context.commit('SET_ZONES', response)
      })
    },
  },
}
