import ASSISTANT_PERMISSIONS from '@/constants/assistant-permissions'

const items = [
  {
    title: 'business.client-app',
    icon: 'LayoutIcon',
    children: [
      {
        title: 'business.info',
        route: 'owner:business-info',
      },
      {
        title: 'business.theme-title',
        route: 'owner:theme',
        assistantPermissions: [ASSISTANT_PERMISSIONS.THEME],
      },
      {
        title: 'business.qr',
        route: 'owner:qr',
      },
      {
        title: 'menu.otp',
        route: 'owner:otp',
      },
      {
        title: 'feedbacks.title',
        route: 'owner:feedbacks',
      },
      {
        title: 'notices.title',
        route: 'owner:notices',
      },
      {
        title: 'notifications.title',
        route: 'owner:notifications',
      },
    ],
  },
]
export default items.map(item => ({ ...item, store: true }))
