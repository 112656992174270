import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import auth from '@/store/bya3/auth'
import navigation from '@/store/bya3/navigation'
import subscription from '@/store/bya3/subscription'
import businessTypes from '@/store/bya3/business-types'
import shop from '@/store/bya3/shop'
import modifier from '@/store/bya3/modifier'
import discounts from '@/store/bya3/discounts'
import coupons from '@/store/bya3/coupons'
import delivery from '@/store/bya3/delivery'
import notifications from '@/store/bya3/notifications'
import notices from '@/store/bya3/notices'
import tables from '@/store/bya3/tables'
import settings from '@/store/bya3/settings'

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    navigation,
    subscription,
    shop,
    businessTypes,
    modifier,
    coupons,
    discounts,
    delivery,
    notifications,
    notices,
    tables,
    settings,
  },
  strict: process.env.DEV,
})
