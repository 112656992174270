import OTPAPIs from '@/api/store/otp-api'

export default {
  namespaced: true,
  state: {
    otp_active: false,
    otp_message: '',
    otpFetched: false,
  },

  getters: {
    otpActive: (state: any) => state.otp_active,
    otpMessage: (state: any) => state.otp_message,
    otpFetched: (state: any) => state.otpFetched,
  },

  mutations: {
    SET_OTP(state: any, data: any) {
      state.otp_active = !!data?.wp_otp_active ?? false
      state.otp_message = data?.otp_message ?? ''
      state.otpFetched = true
    },
  },
  actions: {
    async FETCH_OTP(context: any, force = false) {
      if (context.state.otpFetched && !force) return
      // eslint-disable-next-line consistent-return,no-return-await
      await OTPAPIs.getSettings().then((response: any) => {
        context.commit('SET_OTP', response)
      })
    },
  },
}
