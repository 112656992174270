import Vue from 'vue'
import VueRouter from 'vue-router'
import { BASE_ROUTE_URL } from '@/constants/config'
// Routes
import updateNavigationStoreGuard from '@/router/guards/update-navigation-store-guard'
import multiGuard from '@/router/utils/vue-router-multiguard'
import checkTokenGuard from '@/router/guards/check-token-guard'
import authGuard from '@/router/guards/auth-guard'
import adminRoutes from './admin'
import ownerRoutes from './owner'
import authRoutes from './auth-routes'
import errorRoutes from './error-routes'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: BASE_ROUTE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      beforeEnter: multiGuard([checkTokenGuard, authGuard]),
      redirect: { name: 'owner:home' },
    },
    ...adminRoutes,
    ...ownerRoutes,
    ...authRoutes,
    ...errorRoutes,
  ],
})
router.beforeEach((...params) => multiGuard([updateNavigationStoreGuard])(...params))

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
