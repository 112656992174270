import $http from '@/libs/axios'

class TablesAPIs {
  static async delete(id: number, isHotel = false) {
    return (await $http.delete(`${isHotel ? 'rooms' : 'tables'}/${id}`)).data
  }

  static async deleteFloor(id: number, isHotel: boolean) {
    return (await $http.delete(`${isHotel ? 'hotel-floors' : 'floors'}/${id}`)).data
  }

  static async list() {
    return (await $http.get('tables')).data
  }

  static async listFoors(isHotel = false) {
    return (await $http.get(isHotel ? 'hotel-floors' : 'floors')).data
  }

  static async process(data: any, isHotel = false) {
    return (await $http.post(isHotel ? 'rooms' : 'tables', data)).data
  }

  static async processFloor(data: any, isHotel: boolean) {
    return (await $http.post(isHotel ? 'hotel-floors' : 'floors', data)).data
  }
}

export default TablesAPIs
