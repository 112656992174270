import Vue from 'vue'
import axios from 'axios'
import { API_URL } from '@/constants/config'
import StorageService from '@/services/storage-service'
import store from '@/store'
import i18n from '@/libs/i18n'

const axiosIns = axios.create({
  baseURL: API_URL,
  headers: {
    common: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Pragma: 'no-cache',
      Expires: '0',
      version: '2',
      dashboard: true,
      Accept: 'application/json',
      locale: 'ar',
    },
  },
})
axiosIns.interceptors.request.use(config => {
  const token = StorageService.getItem('bya3_api_token')
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`
  }
  if (store.getters['pages/currentPageId']) {
    // eslint-disable-next-line no-param-reassign
    config.headers['page-id'] = store.getters['pages/currentPageId']
  }
  if (store.getters['instagram/currentAccountId']) {
    // eslint-disable-next-line no-param-reassign
    config.headers['insta-id'] = store.getters['instagram/currentAccountId']
  }
  // eslint-disable-next-line no-param-reassign
  config.headers.social = store.getters['auth/modelType']
  // eslint-disable-next-line no-param-reassign
  config.headers.locale = i18n.locale
  return config
})

Vue.prototype.$http = axiosIns

export default axiosIns
