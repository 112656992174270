<template>
  <div class="d-flex justify-content-between align-items-center">
    <span>{{ $t('general.dark-mode') }}</span>
    <b-form-checkbox
      :checked="isDark"
      class="custom-control-primary ml-1"
      name="check-button"
      switch
      @change="skin = isDark ? 'light' : 'dark'"
    />
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox,
  },
  setup() {
    const { skin } = useAppConfig()

    return { skin }
  },
}
</script>
