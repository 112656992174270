import { NavigationGuardNext, Route } from 'vue-router'
import store from '@/store'

function authGuard(to: Route, from: Route, next: NavigationGuardNext): void {
  const isAuthenticated = store.getters['auth/isAuthenticated']

  if (!isAuthenticated) {
    return next({ name: 'login', query: to.query, hash: '#redirected' })
  }

  if (to.meta.redirectIfLoggedIn && isAuthenticated) return next()
  return next()
}

export default authGuard
