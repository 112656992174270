import $http from '@/libs/axios'

class ItemAPIs {
  static async delete(id: number) {
    return (await $http.delete(`items/${id}`)).data
  }

  static async getById(id: string) {
    return (await $http.get(`items/${id}`)).data
  }

  static async process(data: any) {
    return (await $http.post('items', data)).data
  }

  static async dependencies() {
    return (await $http.get('item/dependencies')).data
  }

  static async search(query = null) {
    let url = 'items'
    if (query) url += `?query=${query}`
    return (await $http.get(url)).data
  }
}

export default ItemAPIs
