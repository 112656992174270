export default {
  namespaced: true,
  state: {
    showTooltip: false,
    couponsCount: null,
  },

  getters: {
    showTooltip: (state: any) => state.showTooltip,
    couponsCount: (state: any) => state.couponsCount,
  },

  mutations: {
    SET_SHOW_TOOLTIP(state: any, payload: any) {
      state.showTooltip = payload
    },
    SET_COUPONS_COUNT(state: any, couponsCount: number) {
      state.couponsCount = couponsCount
    },
  },
}
