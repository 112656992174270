import $http from '@/libs/axios'

class DeliveryZonesAPIs {
  static async delete(id: number) {
    return (await $http.delete(`delivery-zones/${id}`)).data
  }

  static async process(data: any) {
    return (await $http.post('delivery-zones', data)).data
  }

  static async list(query = null, forList = false) {
    let url = 'delivery-zones'
    if (query) url += `?city=${query}`
    if (forList) url += '?for_list=true'
    return (await $http.get(url)).data
  }
}

export default DeliveryZonesAPIs
