export default {
  namespaced: true,
  state: {
    discountsCount: null,
  },

  getters: {
    discountsCount: (state: any) => state.discountsCount,
  },

  mutations: {
    SET_DISCOUNTS_COUNT(state: any, discountsCount: number) {
      state.discountsCount = discountsCount
    },
  },
}
