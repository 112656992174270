import { extend, localize } from 'vee-validate'
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  max as rule_max,
  min_value as rule_min_value,
  max_value as rule_max_value,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  numeric as rule_numeric,
  alpha_num as rule_alpha_num,
  length as rule_length,
} from 'vee-validate/dist/rules'
import ar from 'vee-validate/dist/locale/ar.json'
// eslint-disable-next-line object-curly-newline
import i18n from '@/libs/i18n'
import {
  validatorPositive, validatorUrlValidator, validatorPassword, validatorPhoneValidator,
} from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const email = extend('email', rule_email)

export const max = extend('max', rule_max)

export const min = extend('min', rule_min)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)
export const numeric = extend('numeric', rule_numeric)

export const min_value = extend('min_value', rule_min_value)

export const max_value = extend('max_value', rule_max_value)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: () => i18n.t('validations.positive-number'),
})

export const password = extend('password', {
  validate: validatorPassword,
  message: 'Your {_field_} must contain at least one uppercase, one lowercase, one special character and one digit',
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'الرابط غير صالح، يجب أن يكون من الشكل https://google.com',
})

export const phone = extend('phone', {
  validate: validatorPhoneValidator,
  message: 'الرقم غير صالح، يجب أن يكون من الشكل 0981460789',
})

// Install English and Arabic localizations.
localize('ar', { ar, messages: { ...ar.messages, ...(i18n.t('validations', 'ar') ?? {}) } })
