import multiGuard from '@/router/utils/vue-router-multiguard'
import checkTokenGuard from '@/router/guards/check-token-guard'
import authGuard from '@/router/guards/auth-guard'
import LayoutFull from '@/layouts/full/LayoutFull.vue'

export default [
  {
    path: '/branches',
    component: LayoutFull,
    beforeEnter: multiGuard([checkTokenGuard, authGuard]),
    children: [
      {
        path: '/',
        name: 'owner:branches',
        component: () => import(/* webpackChunkName: "branches" */ '@/views/branch/BranchesTable.vue'),
      },
      {
        path: 'process/:id?',
        name: 'owner:branches-process',
        component: () => import(/* webpackChunkName: "branches" */ '@/views/branch/ProcessBranchPage.vue'),
      },
      {
        path: 'zones',
        name: 'owner:zones',
        component: () => import(/* webpackChunkName: "zones" */ '@/views/store/delivey-zones/DeliveryZonesTable.vue'),
      },
    ],
  },
]
