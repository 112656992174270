import $http from '@/libs/axios'
import FormsService from '@/services/forms-service'

export default class AuthApi {
  static async login(data: any) {
    return (await $http.post('users/login', data)).data
  }

  static async user() {
    return (await $http.get('user/profile')).data
  }

  static async update(data: any) {
    return (await $http.post('user/update', data)).data
  }

  static async updateTheme(data: any) {
    return (await $http.post('user/update-theme', data, { headers: FormsService.MULTIPART_HEADER })).data
  }

  static async deleteFeedback(id: number) {
    return (await $http.delete(`user/feedbacks/${id}`)).data
  }
}
