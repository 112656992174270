import BusinessTypesAPIs from '@/api/admin/business-types-api'

interface BusinessType {
  id: number;
  slug: string;
  icon: string;
  features: Array<string>;
  locales: Array<{
    locale: string;
    name: string;
  }>;
}

export default {
  namespaced: true,
  state: {
    types: null,
    typesFetched: false,
    currentType: {},
  },
  getters: {
    types: (state: any) => state.types ?? [],
    dineInLabel: (state: any) => state.currentType?.features?.dine_in_label ?? 'dinein',
    isProductService: (state: any, getters: any) => getters.dineInLabel !== 'dinein',
    dineInTranslationPath: (state: any, getters: any) => (getters.isProductService ? 'selling-types.types.product-service' : 'selling-types.types.dinein'),
    isEnabled: (state: any) => (feature: string) => state.currentType?.features?.[feature] ?? true,
    businessFeatures: (state: any) => state.currentType?.features,
    tableLabel: (state: any) => state.currentType?.features?.['table_label'],
    floorLabel: (state: any) => state.currentType?.features?.['floor_label'],
    hasFoodMenu: (state: any) => ['restaurant'].includes(state.currentType.slug),
  },
  mutations: {
    SET_BUSINESS_TYPES(state: any, businessTypes: unknown) {
      state.typesFetched = true
      state.types = businessTypes
    },
    SET_BUSINESS_TYPE(state: any, type: any) {
      state.currentType = type
    },
    SET_BUSINESS_TYPE_ID(state: any, typeId: any) {
      state.currentType = (state.types ?? []).find((type: any) => type.id === typeId) ?? state.currentType
    },
  },
  actions: {
    async FETCH_TYPES(context: any) {
      if (context.state.typesFetched) return context.state.types
      const types = await BusinessTypesAPIs.businessTypes()
      context.commit('SET_BUSINESS_TYPES', types)
      return types
    },
  },
}
