export default {
  // Endpoints
  loginEndpoint: '/users/login',
  registerEndpoint: '/users/register',
  checkTokenEndpoint: '/user/profile',
  refreshEndpoint: '/users/refresh-token',
  logoutEndpoint: '/users/logout',

  refreshTokenDisabled: true,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'bya3_api_token',
  storageRefreshTokenKeyName: 'refreshToken',
}
