import guestGuard from '@/router/guards/guest-guard'
import multiGuard from '@/router/utils/vue-router-multiguard'
import checkTokenGuard from '@/router/guards/check-token-guard'
import Login from '@/views/auth/Login.vue'

export default [
  {
    path: '/auth',
    component: () => import(/* webpackChunkName: "admin" */ '@/layouts/full/LayoutFull.vue'),
    beforeEnter: multiGuard([checkTokenGuard, guestGuard]),
    children: [
      {
        path: 'login',
        name: 'login',
        meta: { redirectIfLoggedIn: true, layout: 'full' },
        component: Login,
      },
      {
        path: 'login-master',
        name: 'login-master',
        meta: { redirectIfLoggedIn: true, layout: 'full' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/LoginAdmin.vue'),
      },
    ],
  },
]
