import { Route } from 'vue-router'

export default {
  namespaced: true,
  state: {
    currentRoute: {},
    prevRoute: null,
  },
  getters: {
    currentRoute: (state: any) => state.currentRoute,
    prevRoute: (state: any) => state.prevRoute,
    currentPath: (state: any) => state.currentRoute?.path ?? '',
    isAdminRoute: (state: any, getters: any) => getters.currentPath.includes('admin'),
  },
  mutations: {
    SET_CURRENT_ROUTE(state: any, route: Route) {
      state.prevRoute = state.currentRoute?.to ? state.currentRoute : state.prevRoute
      state.currentRoute = route
    },
    SET_PREV_ROUTE(state: any, route: Route) {
      state.prevRoute = route
    },
  },
}
