import Vue from 'vue'
import OkConfirmModal from '@/@bya3/components/modals/OkConfirmModal.vue'

export default function customConfirm(message: string, props: any = {}) {
  return new Promise(resolve => {
    const dialog = new Vue({
      methods: {
        closeHandler(fn: any, arg: any) {
          return function () {
            fn(arg)
            dialog.$destroy()
            dialog.$el.remove()
          }
        },
      },
      render(h) {
        return h(OkConfirmModal, {
          props: {
            text: message,
            ...props,
          },
          on: {
            // @ts-ignore
            confirm: this.closeHandler(resolve, true),
            // @ts-ignore
            cancel: this.closeHandler(resolve, false),
          },
        })
      },
    }).$mount()
    document.body.appendChild(dialog.$el)
  })
}
