import { NavigationGuardNext, Route } from 'vue-router'
import useAppConfig from '@core/app-config/useAppConfig'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'

async function checkTokenGuard(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
  const isAuthenticated = store.getters['auth/isAuthenticated']
  const impersonate = !!to.query.access_token
  const token = to.query.access_token || useJwt.getToken()

  if (isAuthenticated || !token) {
    next()
    return
  }

  const { layoutType } = useAppConfig()
  const oldLayoutType = 'vertical'// layoutType.value || 'vertical'
  layoutType.value = 'full'

  try {
    await store.dispatch('auth/CHECK_TOKEN', { token, impersonate })
    next()
  } catch (error) {
    next()
  } finally {
    layoutType.value = oldLayoutType
  }
}

export default checkTokenGuard
