import multiGuard from '@/router/utils/vue-router-multiguard'
import checkTokenGuard from '@/router/guards/check-token-guard'
import authGuard from '@/router/guards/auth-guard'
import LayoutFull from '@/layouts/full/LayoutFull.vue'

export default [
  {
    path: '/assistants',
    component: LayoutFull,
    beforeEnter: multiGuard([checkTokenGuard, authGuard]),
    children: [
      {
        path: '/',
        name: 'owner:assistants',
        component: () => import(/* webpackChunkName: "assistants" */ '@/views/assistants/AssistantsTable.vue'),
      },
      {
        path: 'process/:id?',
        name: 'owner:assistants-process',
        component: () => import(/* webpackChunkName: "assistants" */ '@/views/assistants/ProcessAssistantPage.vue'),
      },
    ],
  },
]
