import AuthApis from '@/api/auth'
import StorageService from '@/services/storage-service'
import NotificationsApi from '@/api/notifications'
import useJwt from '@/auth/jwt/useJwt'
import PrintAPIs from '@/api/store/print-api'

export default {
  namespaced: true,
  state: {
    user: null,
    token: null,
    impersonate: false,
    authenticated: false,
    notifications: [],
    notificationsTotal: 0,
    admin: false,
    loader: false,
    totalStorage: 100,
    availableStorage: 100,
    expired: true,
    printSettings: null,
    printSettingsFetched: false,
  },
  getters: {
    isAuthenticated: (state: any) => !!state.user,
    isAdmin: (state: any) => state.user?.admin ?? false,
    isPrimary: (state: any) => state.user?.primary ?? false,
    permissions: (state: any) => state.user?.permissions ?? [],
    isAssistantHasPermission: (state: any, getters: any) => (permission: string) => getters.permissions?.[permission] ?? false,
    user: (state: any) => state.user ?? {},
    branches: (state: any) => state.user.locations ?? [],
    currency: (state: any) => state.user?.currency ?? 'ل.س',
    token: (state: any) => state.token ?? '',
    impersonate: (state: any) => state.impersonate,
    notifications: (state: any) => state.notifications,
    notificationsTotal: (state: any) => state.notificationsTotal,
    loader: (state: any) => state.loader,
    totalStorage: (state: any) => state.totalStorage,
    locales: (state: any) => state.user?.locales ?? ['ar'],
    expired: (state: any) => state.expired ?? false,
    availableStorage: (state: any) => state.availableStorage,
    printSettings: (state: any) => state.printSettings,
    isSuperAdminHasPermission: (state: any) => (permission: string) => state?.user?.permissions?.[permission] ?? false,
  },
  mutations: {
    SET_USER(state: any, user: unknown) {
      state.user = user
    },
    SET_LOADER(state: any, loader: boolean) {
      state.loader = loader
    },
    SET_AUTHENTICATED(state: any, value: boolean) {
      state.authenticated = value
    },
    SET_TOKEN(state: any, token: string) {
      state.token = token
      StorageService.setItem('bya3_api_token', token)
    },
    UPDATE_USER(state: any, user: any) {
      state.user = {
        ...state.user,
        ...user,
      }
    },
    SET_IMPERSONATE(state: any, impersonate: boolean) {
      state.impersonate = impersonate
    },
    SET_STORAGE(state: any, storage: any) {
      state.availableStorage = storage?.availableStorage ?? 100
      state.totalStorage = storage?.totalStorage ?? 100
    },
    SET_ROLES(state: any, payload: any) {
      state.admin = payload?.admin ?? false
      state.referral = payload?.referral ?? false
    },
    SET_NOTIFICATIONS(state: any, notifications: any) {
      state.notifications = notifications
    },
    SET_REFERRALS(state: any, referrals: any) {
      state.referrals = referrals
      state.referralsFetched = true
    },
    SET_NOTIFICATIONS_TOTAL(state: any, total: number) {
      state.notificationsTotal = total
    },
    SET_EXPIRED(state: any, expired: boolean) {
      state.expired = expired
    },
    SET_PRINT_SETTING(state: any, settings: any) {
      state.printSettings = settings
      state.printSettingsFetched = true
    },
  },
  actions: {
    async LOGIN(context: any, { username, password, impersonate = false }: any) {
      let success = false
      await AuthApis.login({ username, password }).then((data: any) => {
        context.dispatch('PREPARE_USER', { ...data, oneTime: impersonate })
        success = true
      }).catch(() => {
        context.commit('SET_USER', null)
        context.commit('SET_ROLES', null)
        context.commit('SET_AUTHENTICATED', false)
      })
      return success
    },
    async CHECK_TOKEN(context: any, { token, impersonate = false }: any) {
      const response = (await useJwt.checkToken(token)).data
      if (!response.user) throw new Error('Set user failed')
      await context.dispatch('PREPARE_USER', { ...response, oneTime: impersonate })
      if (impersonate) {
        context.commit('SET_IMPERSONATE', true)
      }
      return response?.user
    },
    async LOGOUT(context: any) {
      if (context.state.impersonate) {
        context.commit('SET_IMPERSONATE', false)
      }
      context.commit('SET_USER', {})
      context.commit('SET_AUTHENTICATED', false)
      StorageService.removeItem('bya3_api_token')
    },
    async FETCH_USER_INFO(context: any) {
      context.commit('branches/RESET', null, { root: true })
      const token: any = useJwt.getToken()
      return context.dispatch('CHECK_TOKEN', { token, impersonate: context.state.impersonate })
    },
    async PREPARE_USER(context: any, {
      access_token, user, admin, referral, oneTime, storage, subscription, expired,
    }: any) {
      if (!user) throw new Error('Set user failed')
      if (!oneTime) {
        useJwt.setToken(access_token)
        context.commit('SET_TOKEN', access_token)
      }
      context.commit('SET_USER', user)
      context.commit('SET_EXPIRED', expired)
      context.commit('SET_STORAGE', storage)
      context.commit('SET_ROLES', { admin, referral })
      context.commit('shop/SET_BRANCHES', user?.locations ?? [], { root: true })
      context.commit('businessTypes/SET_BUSINESS_TYPE', user.type, { root: true })
      context.commit('subscription/SET_SUBSCRIPTION', subscription, { root: true })
    },
    async FETCH_NOTIFICATIONS(context: any) {
      await NotificationsApi.notifications().then(res => {
        context.commit('SET_NOTIFICATIONS', res.notifications)
        context.commit('SET_NOTIFICATIONS_TOTAL', res.total)
      }).catch(() => {
      })
    },
    async Mark_NOTIFICATION_READ(context: any, id: number) {
      await NotificationsApi.markAsRead(id).then(res => {
        context.commit('SET_NOTIFICATIONS', res.notifications)
        context.commit('SET_NOTIFICATIONS_TOTAL', res.total)
      }).catch(() => {
      })
    },
    async GET_PRINT_SETTINGS(context: any) {
      if (context.state.printSettingsFetched) return
      await PrintAPIs.show().then(res => {
        if (res?.setting?.id) context.commit('SET_PRINT_SETTING', res?.setting)
      }).catch(() => {
      })
    },
  },
}
