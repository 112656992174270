import customConfirm from '@/utils/custom-confirm'

const OkConfirmPlugin = {
  install(Vue: any) {
    // eslint-disable-next-line no-param-reassign
    Vue.$okConfirm = (text: string, props: any = {}) => customConfirm(text, props)
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$okConfirm = Vue.$okConfirm
  },
}

export default OkConfirmPlugin
