import dayjs from 'dayjs'

class StorageService {
  static setItem(key: string, value: any, expires: number | Date = 365) {
    try {
      const expiresDate = typeof expires === 'number' ? dayjs().add(expires, 'days') : dayjs(expires)
      const storedValue = {
        expires: expiresDate.toISOString(),
        value,
      }
      localStorage.setItem(key, JSON.stringify(storedValue))
    } catch (e) {
      console.error('LocalStorage not set probably -> Storage.setItem', e)
    }
  }

  static getItem(key: string, defaultValue: any = undefined) {
    try {
      const storedValue = localStorage.getItem(key)
      let value

      if (!storedValue) return defaultValue

      try {
        value = JSON.parse(storedValue)
      } catch (e) {
        value = storedValue
      }

      if (!value) return defaultValue
      if (!value?.expires) return defaultValue
      if (dayjs(value?.expires).isBefore(dayjs())) {
        StorageService.removeItem(key)
        return defaultValue
      }
      return value?.value ?? defaultValue
    } catch (e) {
      console.error('LocalStorage not set probably -> Storage.getItem', e)
      return defaultValue
    }
  }

  static removeItem(key: string) {
    try {
      localStorage.removeItem(key)
    } catch (e) {
      console.error('LocalStorage not set probably -> Storage.removeItem', e)
    }
  }
}

export default StorageService
