import multiGuard from '@/router/utils/vue-router-multiguard'
import checkTokenGuard from '@/router/guards/check-token-guard'
import authGuard from '@/router/guards/auth-guard'

export default [
  {
    path: '/error',
    component: () => import(/* webpackChunkName: "error-routes" */ '@/layouts/full/LayoutFull.vue'),
    children: [
      {
        path: '/not-found',
        name: 'error-404',
        component: () => import(/* webpackChunkName: "error-routes" */ '@/views/error/Error404.vue'),
        meta: {
          layout: 'full',
        },
      },
      {
        path: '/unauthorized',
        name: 'unauthorized',
        beforeEnter: multiGuard([checkTokenGuard, authGuard]),
        component: () => import(/* webpackChunkName: "error-routes" */ '@/views/error/Unauthorized.vue'),
      },
    ],
  },
  {
    path: '*',
    redirect: 'not-found',
  },
]
