import $http from '@/libs/axios'
import { setFeaturesLocales } from '@/utils/features-helper'

class PlansAPIs {
  static async getPackagesList() {
    return (await $http.get('admin/packages')).data
  }

  static async getFeatures() {
    const { features } = (await $http.get('admin/features')).data
    features.forEach((feature: any, idx: number) => {
      features[idx] = setFeaturesLocales(feature)
    })
    return features
  }

  static async getSubscriptionDep() {
    const { features, plans } = (await $http.get('admin/subscription/dep')).data
    features.forEach((feature: any, idx: number) => {
      features[idx] = setFeaturesLocales(feature)
    })
    return { features, packages: plans }
  }
}

export default PlansAPIs
