import Vue from 'vue'
import VueI18n from 'vue-i18n'
import LocalizationService from '@/services/localization-service'
import ar from './locales/ar'
import en from './locales/en'
// eslint-disable-next-line import/no-cycle

Vue.use(VueI18n)

const locale = LocalizationService.getLocale()

export default new VueI18n({
  locale: locale === 'ar' ? 'ar' : 'en',
  fallbackLocale: 'ar',
  messages: {
    ar,
    en,
  },
})
