import ModifierAPIs from '@/api/store/modifier'

export default {
  namespaced: true,
  state: {
    modifiers: [],
    modifiersFetched: false,
    groups: [],
    groupsFetched: false,
  },
  getters: {
    modifiers: (state: any) => state.modifiers ?? [],
    modifiersFetched: (state: any) => state.modifiersFetched ?? false,
    groups: (state: any) => state.groups ?? [],
    groupsFetched: (state: any) => state.groupsFetched ?? false,
  },
  mutations: {
    SET_MODIFIERS(state: any, modifiers: any) {
      state.modifiers = modifiers
      state.modifiersFetched = true
    },
    SET_GROUPS(state: any, groups: any) {
      state.groups = groups
      state.groupsFetched = true
    },
    UPDATE_MODIFIERS(state: any, newLabel: any) {
      state.modifiers = [...state.modifiers, newLabel]
    },
  },
  actions: {
    async FETCH_MODIFIERS(context: any) {
      if (context.state.modifiersFetched) return
      // eslint-disable-next-line consistent-return,no-return-await
      await ModifierAPIs.list().then(response => {
        context.commit('SET_MODIFIERS', response)
      })
    },
    async FETCH_GROUPS(context: any) {
      if (context.state.groupsFetched) return
      // eslint-disable-next-line consistent-return,no-return-await
      await ModifierAPIs.listGroups().then(response => {
        context.commit('SET_GROUPS', response)
      })
    },
  },
}
