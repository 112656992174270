import { NavigationGuardNext, Route } from 'vue-router'
import store from '@/store'

function updateNavigationStoreGuard(to: Route, from: Route, next: NavigationGuardNext): void {
  store.commit('navigation/SET_CURRENT_ROUTE', to)

  next()
}

export default updateNavigationStoreGuard
