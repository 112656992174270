import { Route, RouteRecord } from 'vue-router'

export function getAssistantPermissionsFromRoute(to: Route) {
  const permissions: Array<Array<string>> = to.matched.reduce((prev: any, current: RouteRecord) => {
    const { assistantPermissions } = current?.meta ?? {}
    return (assistantPermissions ? [...prev, Array.isArray(assistantPermissions) ? assistantPermissions : [assistantPermissions]] : prev)
  }, [])
  return permissions
}

export function isAssistantHasPermissions(permissions: Array<Array<string>>, permissionChecker: Function) {
  for (let i = 0; i < permissions.length; i += 1) {
    const types = permissions[i]
    const isFailed = !types.some((permission: string) => permissionChecker(permission))
    if (isFailed) {
      return false
    }
  }
  return true
}

export function getPackagePermissionsFromRoute(to: Route) {
  const permissions: Array<Array<string>> = to.matched.reduce((prev: any, current: RouteRecord) => {
    const { packagePermissions } = current?.meta ?? {}
    return (packagePermissions ? [...prev, Array.isArray(packagePermissions) ? packagePermissions : [packagePermissions]] : prev)
  }, [])
  return permissions
}

export function isSubscriptionHasPermissions(permissions: Array<Array<string>>, permissionChecker: Function) {
  for (let i = 0; i < permissions.length; i += 1) {
    const types = permissions[i]
    const isFailed = !types.some((permission: string) => permissionChecker(permission))
    if (isFailed) {
      return false
    }
  }
  return true
}

export function getAdminPermissionsFromRoute(to: Route) {
  const permissions: Array<Array<string>> = to.matched.reduce((prev: any, current: RouteRecord) => {
    const { adminPermissions } = current?.meta ?? {}
    return (adminPermissions ? [...prev, Array.isArray(adminPermissions) ? adminPermissions : [adminPermissions]] : prev)
  }, [])
  return permissions
}

export function isSuperAdminHasPermissions(permissions: Array<Array<string>>, permissionChecker: Function) {
  for (let i = 0; i < permissions.length; i += 1) {
    const types = permissions[i]
    const isFailed = !types.some((permission: string) => permissionChecker(permission))
    if (isFailed) {
      return false
    }
  }
  return true
}
