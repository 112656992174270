import $http from '@/libs/axios'

class NoticesAPIs {
  static async delete(id: number) {
    return (await $http.delete(`notice-items/${id}`)).data
  }

  static async list() {
    return (await $http.get('notice-items')).data
  }

  static async process(data: any) {
    return (await $http.post('notice-items', data)).data
  }

  static async processNotice(data: any) {
    return (await $http.post('notice-bar', data)).data
  }

  static async getNotice() {
    return (await $http.get('notice-bar')).data
  }
}

export default NoticesAPIs
