import managementRoutes from '@/navigation/vertical/admin/management'
import technicalRoutes from '@/navigation/vertical/admin/technical'

const items = [
  {
    title: 'menu.admin-home',
    route: 'admin:home',
    icon: 'HomeIcon',
  },
  ...managementRoutes,
  ...technicalRoutes,
]

export default items.map(item => ({ ...item, admin: true }))
