import authGuard from '@/router/guards/auth-guard'
import checkTokenGuard from '@/router/guards/check-token-guard'
import multiGuard from '@/router/utils/vue-router-multiguard'
import userRoutes from '@/router/owner/user'
import storeRoutes from '@/router/owner/store'
import assistantRoutes from '@/router/owner/assistant'
import branchesRoutes from '@/router/owner/branches'

const ownerRoutes = [
  {
    path: '/dashboard',
    beforeEnter: multiGuard([checkTokenGuard, authGuard]),
    component: () => import(/* webpackChunkName: "owner" */ '@/layouts/full/LayoutFull.vue'),
    children: [
      {
        path: '',
        name: 'owner:home',
        component: () => import(/* webpackChunkName: "owner" */ '@/views/dashboard/Home.vue'),
      },
      {
        path: 'help',
        name: 'owner:help',
        component: () => import(/* webpackChunkName: "help" */ '@/views/help/HelpPosts.vue'),
      },
      ...userRoutes,
      ...storeRoutes,
      ...assistantRoutes,
      ...branchesRoutes,
    ],
  },
]

export default ownerRoutes
