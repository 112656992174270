import NotificationsApi from '@/api/notifications'

export default {
  namespaced: true,
  state: {
    telegram: {
      enabled: 0,
      token: '',
    },
    email: {},
    fetchedSettings: false,
  },
  getters: {
    telegram: (state: any) => state.telegram ?? {},
    email: (state: any) => state.email ?? {},
  },
  mutations: {
    SET_SETTINGS(state: any, body: any) {
      state.telegram = body?.telegram ?? { enabled: 0, token: '' }
      state.email = body?.email
      state.fetchedSettings = true
    },
    UPDATE_TELEGRAM(state: any, body: any) {
      state.telegram = { ...body }
    },
    UPDATE_EMAIL(state: any, body: any) {
      state.email = { ...body }
    },
  },
  actions: {
    async FETCH_SETTINGS(context: any) {
      if (context.state.fetchedSettings) return
      // eslint-disable-next-line consistent-return,no-return-await
      await NotificationsApi.settings().then(response => {
        context.commit('SET_SETTINGS', response)
      })
    },
  },
}
