import { localize } from 'vee-validate'
import StorageService from '@/services/storage-service'
import i18n from '@/libs/i18n'
import store from '@/store'

export default class LocalizationService {
    private static readonly storageKey = 'bya3_locale';

    static initLocale() {
      const storedLocale = StorageService.getItem(LocalizationService.storageKey)
      const locale = (storedLocale || 'ar').substring(0, 2)
      this.updateLocale(locale)
    }

    static updateLocale(locale: string) {
      i18n.locale = locale
      LocalizationService.updateDirection(locale)
      import(/* webpackChunkName: "lang-[request]" */ `vee-validate/dist/locale/${locale}.json`)
        .then(({ messages, code }) => {
          // @ts-ignore
          localize(locale, { code, messages: { ...messages, ...(i18n.t('validations', locale) ?? {}) } })
        })
        .catch(error => { console.error('Error while fetching validation messages', error) })
    }

    static translate(key: string) {
      return i18n.t(key)
    }

    static setLocale(locale: string) {
      LocalizationService.updateLocale(locale)
      StorageService.setItem(LocalizationService.storageKey, locale)
    }

    static getLocale() {
      return StorageService.getItem(LocalizationService.storageKey) ?? 'ar'
    }

    static getCurrentLocale() {
      return i18n.locale
    }

    static updateDirection(locale: string) {
      const shouldRTL = locale === 'ar'
      store.commit('appConfig/SET_RTL', shouldRTL)

      if (document?.documentElement?.setAttribute) {
        document.documentElement.setAttribute('dir', shouldRTL ? 'rtl' : 'ltr')
        document.documentElement.setAttribute('lang', locale)
      }
    }

    static getTranslate(object: any, attributeName = 'name', locale?: string, withoutFallback = false) {
      const currentLocale = locale ?? LocalizationService.getLocale()
      const localesArray = object?.locales ?? object
      if (!Array.isArray(localesArray)) {
        return ''
      }
      const attributeValue = localesArray?.find((l: any) => l.locale === currentLocale)?.[attributeName]
      return attributeValue ?? (withoutFallback ? '' : localesArray?.find((l: any) => !!l?.[attributeName])?.[attributeName] ?? '')
    }

    static getTranslateByLocales(object: any, attributeName = 'title', locale?: string, withoutFallback = false) {
      const currentLocale = locale ?? LocalizationService.getLocale()
      if (typeof object[`${currentLocale}_${attributeName}`] !== 'undefined') return object[`${currentLocale}_${attributeName}`]
      if (withoutFallback) return ''
      const anotherlocale = currentLocale === 'ar' ? 'en' : 'ar'
      if (typeof object[`${anotherlocale}_${attributeName}`] !== 'undefined') return object[`${anotherlocale}_${attributeName}`]
      return ''
    }

    static getTranslatedLocale(object: any, locale?: string, withoutFallback = false) {
      return LocalizationService.getTranslate(object, 'locale', locale, withoutFallback)
    }

    static getLocaleIndex(object: any, locale: string) {
      const localesArray = object?.locale ?? object?.locales ?? object
      if (!Array.isArray(localesArray)) {
        return -1
      }
      return localesArray?.findIndex((l: any) => l.locale === locale) ?? -1
    }

    static getTranslateArEn(object: any, attributeName = 'name') {
      const currentLocale = LocalizationService.getCurrentLocale()
      const label = object[`${currentLocale}_${attributeName}`] || ''
      return label === '' ? (object[`ar_${attributeName}`] ?? object[`en_${attributeName}`] ?? '') : label
    }
}
