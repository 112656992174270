import $http from '@/libs/axios'

class CategoryAPIs {
  static async delete(id: number) {
    return (await $http.delete(`categories/${id}`)).data
  }

  static async search(query = null) {
    let url = 'categories'
    if (query) url += `?query=${query}`
    return (await $http.get(url)).data
  }

  static async process(data: any) {
    return (await $http.post('categories', data)).data
  }

  static async getById(id: string) {
    return (await $http.get(`categories/${id}`)).data
  }
}

export default CategoryAPIs
