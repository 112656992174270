import $http from '@/libs/axios'

export default class NotificationsApi {
  static async markAsRead(id: any) {
    return (await $http.post(`notifications/mark-read/${id}`, {})).data
  }

  static async notifications() {
    return (await $http.get('notifications')).data
  }

  static async settings() {
    return (await $http.get('notifications/settings')).data
  }

  static async delete(id: any) {
    return (await $http.delete(`notifications/delete/${id}`)).data
  }

  static async updateTelegram(body: any) {
    return (await $http.post('telegram/update', body)).data
  }

  static async checkTelegram(form: any) {
    return (await $http.post('telegram/check', form)).data
  }

  static async testTelegram() {
    return (await $http.get('telegram/test')).data
  }
}
