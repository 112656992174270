import ASSISTANT_PERMISSIONS from '@/constants/assistant-permissions'

const items = [
  {
    title: 'store.categories',
    icon: 'TagIcon',
    assistantPermissions: [ASSISTANT_PERMISSIONS.MENU],
    children: [
      {
        title: 'menu.main-categories',
        route: 'owner:store-categories',
        assistantPermissions: [ASSISTANT_PERMISSIONS.MENU],
      },
      {
        title: 'menu.subcategories',
        route: 'owner:store-subcategories',
        assistantPermissions: [ASSISTANT_PERMISSIONS.MENU],
      },
    ],
  },
]
export default items.map(item => ({ ...item, store: true }))
