import multiGuard from '@/router/utils/vue-router-multiguard'
import checkTokenGuard from '@/router/guards/check-token-guard'
import authGuard from '@/router/guards/auth-guard'
import LayoutFull from '@/layouts/full/LayoutFull.vue'

export default [
  {
    path: '/user',
    component: LayoutFull,
    beforeEnter: multiGuard([checkTokenGuard, authGuard]),
    children: [
      {
        path: 'account-info',
        name: 'owner:account-info',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/store/settings/ProcessBusinessInfoPage.vue'),
      },
      {
        path: 'business-info',
        name: 'owner:business-info',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/theme/BusinessInfoPage.vue'),
      },
      {
        path: 'theme',
        name: 'owner:theme',
        component: () => import(/* webpackChunkName: "theme" */ '@/views/theme/ProcessThemePage.vue'),
      },
      {
        path: 'qr',
        name: 'owner:qr',
        component: () => import(/* webpackChunkName: "qr" */ '@/views/theme/QRGenerator.vue'),
      },
      {
        path: 'notifications',
        name: 'owner:notifications',
        component: () => import(/* webpackChunkName: "notifications" */ '@/views/user/notifications/NotificationSettings.vue'),
      },
      {
        path: 'invoices',
        name: 'owner:invoices',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/invoices/InvoicesTable.vue'),
      },
      {
        path: 'feedbacks',
        name: 'owner:feedbacks',
        component: () => import(/* webpackChunkName: "feedbacks" */ '@/views/user/feedback/FeedbackTable.vue'),
      },
      {
        path: 'notices',
        name: 'owner:notices',
        component: () => import(/* webpackChunkName: "notices" */ '@/views/user/notices/NoticesPage.vue'),
      },
      {
        path: 'storage',
        name: 'owner:cloud',
        component: () => import(/* webpackChunkName: "cloud" */ '@/views/media/CloudListStep.vue'),
      },
      {
        path: 'otp',
        name: 'owner:otp',
        component: () => import(/* webpackChunkName: "cloud" */ '@/views/store/settings/OTPSettingPage.vue'),
      },
    ],
  },
]
