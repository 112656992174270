import ASSISTANT_PERMISSIONS from '@/constants/assistant-permissions'
import store from '@/store'
import { BUSINESS_FEATURES } from '@/constants/business-types'

const items = [
  {
    title: 'discounts.title',
    icon: 'PercentIcon',
    assistantPermissions: [ASSISTANT_PERMISSIONS.DISCOUNTS, ASSISTANT_PERMISSIONS.COUPONS],
    hide: () => !store.getters['businessTypes/isEnabled'](BUSINESS_FEATURES.COUPONS) && !store.getters['businessTypes/isEnabled'](BUSINESS_FEATURES.DISCOUNTS),
    children: [
      {
        title: 'discounts.title',
        route: 'owner:discounts',
        hide: () => !store.getters['businessTypes/isEnabled'](BUSINESS_FEATURES.DISCOUNTS),
        assistantPermissions: [ASSISTANT_PERMISSIONS.DISCOUNTS],
      },
      {
        title: 'coupons.title',
        route: 'owner:coupons',
        assistantPermissions: [ASSISTANT_PERMISSIONS.COUPONS],
        hide: () => !store.getters['businessTypes/isEnabled'](BUSINESS_FEATURES.COUPONS),
      },
    ],
  },

]
export default items.map(item => ({ ...item, store: true }))
