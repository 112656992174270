import $http from '@/libs/axios'

class ModifierAPIs {
  static async delete(id: number) {
    return (await $http.delete(`modifiers/${id}`)).data
  }

  static async list() {
    return (await $http.get('modifiers')).data
  }

  static async getById(id: string) {
    return (await $http.get(`modifiers/${id}`)).data
  }

  static async process(data: any) {
    return (await $http.post('modifiers', data)).data
  }

  static async listGroups() {
    return (await $http.get('modifier-groups')).data
  }

  static async deleteGroup(id: number) {
    return (await $http.delete(`modifier-groups/${id}`)).data
  }

  static async getGroupById(id: string) {
    return (await $http.get(`modifier-groups/${id}`)).data
  }

  static async processGroup(data: any) {
    return (await $http.post('modifier-groups', data)).data
  }
}

export default ModifierAPIs
