const NavigationPermissionMixin = {
  methods: {
    checkSuperAdminRoute(item) {
      const isAdminRoute = this.$store.getters['navigation/isSuperAdminRoute']
      const isSuperAdmin = this.$store.getters['permissions/isSuperAdmin']

      return (!item.superAdmin && !isAdminRoute) || (item?.superAdmin && isAdminRoute && isSuperAdmin)
    },
    checkAdminPermission(item) {
      if (!item.adminPermissions || !item.adminPermissions?.length) return true
      const isSuperAdmin = this.$store.getters['auth/isAdmin']

      return isSuperAdmin && item.adminPermissions.some(permission => this.$store.getters['auth/isSuperAdminHasPermission'](permission))
    },
    checkAssistantPermission(item) {
      const isPrimary = this.$store.getters['auth/isPrimary']
      if (isPrimary || item.all) return true
      if (!item.assistantPermissions || !item.assistantPermissions?.length) return false

      return item.assistantPermissions.some(permission => this.$store.getters['auth/isAssistantHasPermission'](permission))
    },
    canView(item) {
      if (item.hide && typeof item.hide === 'function' && item.hide()) return false
      return this.checkSuperAdminRoute(item) && this.checkAssistantPermission(item) && this.checkAdminPermission(item)
    },
  },
}

export default NavigationPermissionMixin
