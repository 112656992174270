import ADMIN_PERMISSIONS from '@/constants/admin-permissions'

export default [
  {
    header: 'menu.subscriptions-management',
    adminPermissions: [ADMIN_PERMISSIONS.SUPER_ADMIN],
  },
  {
    title: 'menu.accounts',
    route: 'admin:accounts',
    icon: 'UsersIcon',
    adminPermissions: [ADMIN_PERMISSIONS.SUPER_ADMIN],
  },
  {
    title: 'menu.packages',
    route: 'admin:plans',
    icon: 'TagIcon',
    adminPermissions: [ADMIN_PERMISSIONS.SUPER_ADMIN],
  },
  {
    title: 'menu.business-types',
    route: 'admin:business-types',
    icon: 'ListIcon',
    adminPermissions: [ADMIN_PERMISSIONS.SUPER_ADMIN],
  },
  {
    title: 'menu.invoices',
    route: 'admin:receipts',
    icon: 'DollarSignIcon',
    adminPermissions: [ADMIN_PERMISSIONS.SUPER_ADMIN],
  },
  {
    title: 'menu.admin-help',
    adminPermissions: [ADMIN_PERMISSIONS.SUPER_ADMIN, ADMIN_PERMISSIONS.GUIDE],
    children: [
      {
        title: 'store.categories',
        route: 'admin:help-categories',
        icon: 'HelpCircleIcon',
      },
      {
        title: 'help.sections',
        route: 'admin:help-sections',
        icon: 'HelpCircleIcon',
      },
    ],
  },
]
