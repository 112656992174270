<template>
  <ul>
    <template v-for="item in filteredItems">
      <component
        :is="resolveNavItemComponent(item)"
        v-if="canView(item)"

        :key="item.header || item.title"
        :item="item"
      />
    </template>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import NavigationPermissionMixin from '@/mixins/navigation-permission-mixin'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  mixins: [NavigationPermissionMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    filteredItems() {
      const isAdminRoute = this.$store.getters['navigation/isAdminRoute']
      const isSuperAdmin = this.$store.getters['auth/isAdmin']
      if (isAdminRoute && isSuperAdmin) return this.items.filter(item => item.admin === true)
      return this.items.filter(item => !item?.admin)
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  methods: {
    checkSuperAdminRoute(item) {
      const isAdminRoute = this.$store.getters['navigation/isAdminRoute']
      const isSuperAdmin = this.$store.getters['auth/isAdmin']

      return (!item.admin && !isAdminRoute) || (item?.admin && isAdminRoute && isSuperAdmin)
    },
  },
}
</script>
