import ASSISTANT_PERMISSIONS from '@/constants/assistant-permissions'
import store from '@/store'
import { BUSINESS_FEATURES } from '@/constants/business-types'

const items = [
  {
    title: 'tables.tables',
    icon: 'GridIcon',
    assistantPermissions: [ASSISTANT_PERMISSIONS.TABLES],
    hide: () => !store.getters['businessTypes/isEnabled'](BUSINESS_FEATURES.TABLES),
    children: [
      {
        title: 'tables.floors',
        route: 'owner:floors',
      },
      {
        title: 'tables.tables',
        route: 'owner:tables',
      },
    ],
  },
  {
    title: 'tables.rooms',
    icon: 'GridIcon',
    assistantPermissions: [ASSISTANT_PERMISSIONS.TABLES],
    hide: () => !store.getters['businessTypes/isEnabled'](BUSINESS_FEATURES.ROOMS),
    children: [
      {
        title: 'tables.floors',
        route: 'owner:hotel:floors',
      },
      {
        title: 'tables.rooms',
        route: 'owner:rooms',
      },
    ],
  },
]
export default items.map(item => ({ ...item, store: true }))
