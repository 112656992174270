import store from '@/store'
import { SERVER_URL } from '@/constants/config'
import ADMIN_PERMISSIONS from '@/constants/admin-permissions'

export default [
  {
    header: 'menu.technical',
    adminPermissions: [ADMIN_PERMISSIONS.SUPER_ADMIN],
  },
  {
    title: 'menu.logs-viewer',
    href: () => `${SERVER_URL}/admin/log-viewer?access_token=${store.getters['auth/token']}`,
    icon: 'EyeIcon',
    adminPermissions: [ADMIN_PERMISSIONS.SUPER_ADMIN],
  },
  {
    title: 'menu.telescope',
    href: () => `${SERVER_URL}/telescope?access_token=${store.getters['auth/token']}`,
    icon: 'SearchIcon',
    adminPermissions: [ADMIN_PERMISSIONS.SUPER_ADMIN],
  },
  {
    title: 'menu.horizon',
    href: () => `${SERVER_URL}/horizon?access_token=${store.getters['auth/token']}`,
    icon: 'SquareIcon',
    adminPermissions: [ADMIN_PERMISSIONS.SUPER_ADMIN],
  },
  {
    title: 'menu.documentation',
    href: () => 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/dashboard/ecommerce',
    icon: 'FileTextIcon',
    adminPermissions: [ADMIN_PERMISSIONS.SUPER_ADMIN],
  },
]
