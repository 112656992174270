import { NavigationGuardNext, Route } from 'vue-router'
import store from '@/store'

function guestGuard(to: Route, from: Route, next: NavigationGuardNext): void {
  const isAuthenticated = store.getters['auth/isAuthenticated']

  if (isAuthenticated) return next({ name: 'dashboard', query: to.query })
  return next()
}

export default guestGuard
