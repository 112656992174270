export const BASE_ROUTE_URL = process.env.BASE_URL ?? '/'

export const OWNER_ROOT = '/owner'
export const ADMIN_ROOT = '/admin'
export const API_URL = process.env.VUE_APP_API_SERVER ?? 'http://bya3.test/api'
export const SERVER_URL = process.env.VUE_APP_SERVER ?? 'http://bya3.test/'
export const FACEBOOK_CLIENT_ID = process.env.VUE_APP_FACEBOOK_APP_CLIENT_ID
export const WEBAPP_URL = process.env.VUE_APP_WEBAPP_URL ?? '.ms-nuxt.local:3002'
export const INSTAGRAM_REDIRECT_URL = process.env.VUE_APP_INSTAGRAM_REDIRECT_URL
export const SERVER_TIMEZONE = process.env.VUE_APP_SERVER_TIMEZONE || 'Asia/Damascus'
export const SERVER_OFFSET = process.env.VUE_APP_SERVER_OFFSET || '+02:00'
export const GOOGLE_MAK_KEY = process.env.VUE_APP_GOOGLE_MAK_KEY
