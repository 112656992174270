import multiGuard from '@/router/utils/vue-router-multiguard'
import checkTokenGuard from '@/router/guards/check-token-guard'
import authGuard from '@/router/guards/auth-guard'
import LayoutFull from '@/layouts/full/LayoutFull.vue'
import ASSISTANT_PERMISSIONS from '@/constants/assistant-permissions'

export default [
  {
    path: '/store',
    component: LayoutFull,
    beforeEnter: multiGuard([checkTokenGuard, authGuard]),
    children: [
      {
        path: 'categories',
        name: 'owner:store-categories',
        meta: { assistantPermissions: [ASSISTANT_PERMISSIONS.MENU] },

        component: () => import(/* webpackChunkName: "categories" */ '@/views/store/categories/CategoriesTable.vue'),
      },
      {
        path: 'subcategories',
        name: 'owner:store-subcategories',
        meta: { assistantPermissions: [ASSISTANT_PERMISSIONS.MENU] },
        component: () => import(/* webpackChunkName: "categories" */ '@/views/store/categories/SubCategoriesTable.vue'),
      },
      {
        path: 'categories/process/:id?',
        name: 'owner:store-categories-process',
        meta: { assistantPermissions: [ASSISTANT_PERMISSIONS.MENU] },
        component: () => import(/* webpackChunkName: "categories" */ '@/views/store/categories/ProceeParentPage.vue'),
      },
      {
        path: 'subcategories/process/:id?',
        name: 'owner:store-subcategories-process',
        meta: { assistantPermissions: [ASSISTANT_PERMISSIONS.MENU] },
        component: () => import(/* webpackChunkName: "categories" */ '@/views/store/categories/ProceeChildPage.vue'),
      },
      {
        path: 'products',
        name: 'owner:store-products',
        meta: { assistantPermissions: [ASSISTANT_PERMISSIONS.MENU] },
        component: () => import(/* webpackChunkName: "products" */ '@/views/store/products/ProductsTable.vue'),
      },
      {
        path: 'price-labels',
        name: 'owner:price-labels',
        meta: { assistantPermissions: [ASSISTANT_PERMISSIONS.MENU] },
        component: () => import(/* webpackChunkName: "price-labels" */ '@/views/store/price-labels/PriceLabelsTable.vue'),
      },
      {
        path: 'modifier-groups',
        name: 'owner:modifier-groups',
        meta: { assistantPermissions: [ASSISTANT_PERMISSIONS.MODIFIERS] },
        component: () => import(/* webpackChunkName: "modifiers" */ '@/views/store/modifiers/ModifierGroupTable.vue'),
      },
      {
        path: 'modifiers',
        name: 'owner:modifiers',
        meta: { assistantPermissions: [ASSISTANT_PERMISSIONS.MODIFIERS] },
        component: () => import(/* webpackChunkName: "modifiers" */ '@/views/store/modifiers/ModifiersTable.vue'),
      },
      {
        path: 'products/process/:id?',
        name: 'owner:store-product',
        meta: { assistantPermissions: [ASSISTANT_PERMISSIONS.MENU] },
        component: () => import(/* webpackChunkName: "products" */ '@/views/store/products/ProcessItemPage.vue'),
      },
      {
        path: 'coupons',
        name: 'owner:coupons',
        meta: { assistantPermissions: [ASSISTANT_PERMISSIONS.COUPONS] },

        component: () => import(/* webpackChunkName: "coupons" */ '@/views/coupons/CouponsTable.vue'),
      },
      {
        path: 'discounts',
        name: 'owner:discounts',
        meta: { assistantPermissions: [ASSISTANT_PERMISSIONS.DISCOUNTS] },
        component: () => import(/* webpackChunkName: "discounts" */ '@/views/discounts/DiscountsTable.vue'),
      },
      {
        path: 'discounts/process/:id?',
        name: 'owner:discounts-process',
        meta: { assistantPermissions: [ASSISTANT_PERMISSIONS.DISCOUNTS] },
        component: () => import(/* webpackChunkName: "discounts" */ '@/views/discounts/ProcessDiscountPage.vue'),
      },
      {
        path: 'orders',
        name: 'owner:orders',
        meta: { assistantPermissions: [ASSISTANT_PERMISSIONS.ORDERS] },
        component: () => import(/* webpackChunkName: "orders" */ '@/views/orders/OrdersTable.vue'),
      },
      {
        path: 'floors',
        name: 'owner:floors',
        meta: { assistantPermissions: [ASSISTANT_PERMISSIONS.TABLES] },
        component: () => import(/* webpackChunkName: "floors" */ '@/views/tables/FloorsPage.vue'),
      },
      {
        path: 'sections',
        name: 'owner:hotel:floors',
        meta: { assistantPermissions: [ASSISTANT_PERMISSIONS.TABLES] },
        component: () => import(/* webpackChunkName: "floors" */ '@/views/tables/HotelFloorsPage.vue'),
      },
      {
        path: 'tables',
        name: 'owner:tables',
        meta: { assistantPermissions: [ASSISTANT_PERMISSIONS.TABLES] },
        component: () => import(/* webpackChunkName: "floors" */ '@/views/tables/TablesPage.vue'),
      },
      {
        path: 'rooms',
        name: 'owner:rooms',
        meta: { assistantPermissions: [ASSISTANT_PERMISSIONS.TABLES] },
        component: () => import(/* webpackChunkName: "floors" */ '@/views/tables/RoomsPage.vue'),
      },
      {
        path: 'print',
        name: 'owner:print',
        component: () => import(/* webpackChunkName: "print" */ '@/views/orders/ReceiptSettings.vue'),
      },
    ],
  },
]
