// @ts-ignore
import mapDeep from 'deepdash/mapDeep'
import LocalizationService from '@/services/localization-service'

/**
 * Static Service class to handle different task on MyMenu
 */
class ShopService {
  /**
   * Recursion function to get a flatted array of menu for categories only
   *
   *
   * @param {Array} categories menu
   * @param {Object} parent for recursion
   * @param {number} depth for recursion
   */
  static flatCategories(categories: any, parent?: any, depth = 0): any {
    if (!Array.isArray(categories)) return categories

    let parentName = parent?.name
    if (parent?.parent_name) {
      parentName = `${parent?.parent_name} / ${parentName}`
    }

    return categories.reduce((acc: any, el: any) => {
      const category = {
        ...el, name: LocalizationService.getTranslate(el), parent_name: parentName, has_items: (el?.items_count ?? 0) > 0, has_subcategories: !!el.subcategories?.length, level: depth,
      }
      return acc.concat(el.subcategories?.length ? [category, ...ShopService.flatCategories(el.subcategories, el, depth + 1)] : [category])
    }, [])
  }

  /**
   * Get a flatted array of all items of specific categories
   *
   * @param {Array} categories
   */
  static flatItems(categories: any): any {
    return mapDeep(categories, (item: any) => item, { leavesOnly: true, childrenPath: ['subcategories', 'items'], rootIsChildren: true }).filter((item: any) => !item?.is_category)
  }
}

export default ShopService
