import { Route, RouteRecord } from 'vue-router'
import LocalizationService from '@/services/localization-service'
import i18n from '@/libs/i18n'
import FEATURES from '@/constants/features'

export function getFeaturesFromRoute(to: Route) {
  const features: Array<Array<string>> = to.matched.reduce((prev: any, current: RouteRecord) => {
    const { pageFeatures } = current?.meta ?? {}
    return (pageFeatures ? [...prev, Array.isArray(pageFeatures) ? pageFeatures : [pageFeatures]] : prev)
  }, [])
  return features
}

export function isPageHasFeatures(features: Array<Array<string>>, featuresChecker: Function) {
  for (let i = 0; i < features.length; i += 1) {
    const pageFeatures = features[i]
    const isFailed = !pageFeatures.some((feature: string) => featuresChecker(feature))
    if (isFailed) {
      return false
    }
  }
  return true
}

export function setFeaturesLocales(feat: any) {
  const feature = feat
  feature.name = LocalizationService.getTranslate(feature)
  feature.title = feature.name
  feature.description = LocalizationService.getTranslate(feature, 'description')
  feature.unit = ''
  feature.defaultValue = ''

  switch (feature.slug) {
    case FEATURES.DEVICE: feature.unit = i18n.t('devices.devices'); feature.defaultValue = 5; break
    case FEATURES.BRANCHES: feature.unit = i18n.t('branch.branches'); feature.defaultValue = 2; break
    case FEATURES.CLOUD: feature.unit = 'MB'; feature.defaultValue = 1024; break
    case FEATURES.LANGUAGE: feature.unit = i18n.t('my-menu.language'); feature.defaultValue = 10; break
    case FEATURES.QR_ITEMS: feature.unit = i18n.tc('my-menu.item', 2); feature.defaultValue = 20; break
    case FEATURES.SUB_USERS: feature.unit = i18n.tc('users.users', 2); feature.defaultValue = 5; break
    default: break
  }
  return feature
}
