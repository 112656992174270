const ASSISTANT_PERMISSIONS = {
  MENU: 'menu',
  MODIFIERS: 'modifiers',
  ORDERS: 'orders',
  // STATISTICS: 'statistics',
  DISCOUNTS: 'discounts',
  COUPONS: 'coupons',
  // CLIENTS: 'clients',
  THEME: 'theme',
  TABLES: 'tables',
}

export default ASSISTANT_PERMISSIONS
