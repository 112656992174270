import ownerItems from '@/navigation/vertical/owner'
import store from '@/store'
import flattenDeep from 'lodash/flattenDeep'
import adminItems from '@/navigation/vertical/admin/index.ts'

const items = [...ownerItems, ...adminItems]

function getChildren(item) {
  if (!item.children) {
    return [item]
  }
  return [item].concat(item.children.map(child => ({ ...child, icon: child.icon || item.icon })).map(child => getChildren(child)))
}

export function pages() {
  const isAdminRoute = store.getters['navigation/isAdminRoute']
  return flattenDeep((isAdminRoute ? adminItems : ownerItems).map(item => getChildren(item))).filter(value => !!value)
}

export default items
