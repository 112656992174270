import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router'

function isUndefined(value: any) {
  return value === undefined
}

function evaluateGuards(guards: Array<any>, to: Route, from: Route, next: NavigationGuardNext) {
  const guardsLeft = guards.slice(0) // clone the array so we do not accidentally modify it
  const nextGuard = guardsLeft.shift()

  if (isUndefined(nextGuard)) {
    next()
    return
  }

  nextGuard(to, from, (nextArg: any) => {
    if (isUndefined(nextArg)) {
      evaluateGuards(guardsLeft, to, from, next)
      return
    }

    next(nextArg)
  })
}

function multiGuard(guards: Array<any>): NavigationGuard {
  if (!Array.isArray(guards)) {
    throw new Error('You must specify an array of guards')
  }

  return (to: Route, from: Route, next: NavigationGuardNext) => evaluateGuards(guards, to, from, next)
}

export default multiGuard
