import $http from '@/libs/axios'

class BusinessTypesAPIs {
  static async businessTypes() {
    return (await $http.get('admin/business-types')).data
  }

  static async delete(id: number) {
    return (await $http.delete(`admin/business-types/${id}`)).data
  }

  static async process(data: any) {
    return (await $http.post('admin/business-types', data)).data
  }
}

export default BusinessTypesAPIs
