import { NavigationGuardNext, Route } from 'vue-router'
import store from '@/store'

function adminGuard(to: Route, from: Route, next: NavigationGuardNext): void {
  if (store.getters['auth/isAdmin']) {
    return next()
  }

  return next({ name: 'unauthorized' })
}

export default adminGuard
