<template>
  <b-alert
    v-if="error"
    :show="true"
    :variant="variant"
    class="error-alert"
    :dismissible="dismissible"
  >
    <div class="font-weight-bold">
      <slot name="title">
        {{ errorTitle }}
      </slot>
    </div>
    <slot>
      <div
        class="font-weight-semi-bold"
        v-html="errorMessage"
      />
    </slot>
  </b-alert>
</template>

<script>
import { BAlert } from 'bootstrap-vue'
import ErrorHandler from '@/utils/error-handler'

export default {
  name: 'ErrorAlert',
  components: { BAlert },
  props: {
    error: {
      type: [Object, String, Error, Boolean],
      default: '',
    },
    dismissible: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: 'danger',
    },
  },
  computed: {
    errorDetails() {
      if (!this.error) return {}
      return this.error?.title ? this.error : ErrorHandler.getErrorDetails(this.error)
    },
    errorTitle() {
      return this.errorDetails.title
    },
    errorMessage() {
      return this.errorDetails.message
    },
  },
}
</script>
