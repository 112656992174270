<template>
  <div class="ok-confirm-modal">
    <!-- overlay -->
    <div
      v-if="visible"
      class="overlay"
      @click="disableOutsideClick ? null : $emit('cancel')"
    />

    <form
      v-if="visible"
      class="ok-modal"
      :class="customClass"
      @change="$event ? null : $emit('cancel')"
      @submit.prevent="remove"
    >
      <div
        v-if="showTitle"
        class="ok-confirm-title"
      >
        <span>{{ titleText }}</span>

        <b-button
          class="btn-icon"
          variant="link"
          @click="$emit('cancel')"
        >
          <feather-icon
            icon="XIcon"
            class="text-secondary"
            size="14"
          />
        </b-button>
      </div>

      <div class="ok-confirm-content">
        <error-alert :error="error" />
        {{ text }}
        <slot />
      </div>

      <div class="ok-confirm-footer">
        <b-button
          variant="dark"
          block
          type="submit"
          :disabled="!!loading || disableConfirmButton"
        >
          <b-spinner
            v-if="loading"
            small
          />
          <span v-else>{{ deleteLabel }}</span>
        </b-button>

        <b-button
          v-if="showCancel"
          class="cancel-btn"
          variant="outline-dark"
          block
          :disabled="!!loading"
          @click="$emit('cancel')"
        >
          <span>{{ cancelLabel }}</span>
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import i18n from '@/libs/i18n'

export default {
  name: 'OkConfirmModal',
  components: {
    ErrorAlert, BButton, BSpinner,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
    preConfirm: {
      type: Function,
      default: null,
    },
    confirmButtonText: {
      type: String,
      default: '',
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    cancelButtonText: {
      type: String,
      default: '',
    },
    disableOutsideClick: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    disableConfirmButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: true,
      error: null,
      loading: false,
    }
  },
  computed: {
    deleteLabel() {
      return this.confirmButtonText || i18n.t('forms.delete')
    },
    cancelLabel() {
      return this.cancelButtonText || i18n.t('forms.cancel')
    },
    titleText() {
      return this.title || i18n.t('forms.are-you-sure')
    },
  },
  methods: {
    remove() {
      if (this.loading) return null

      if (!this.preConfirm || typeof this.preConfirm !== 'function') {
        return this.$emit('confirm')
      }

      this.loading = true
      this.error = null
      return Promise.resolve(this.preConfirm())
        .then(() => this.$emit('confirm'))
        .catch(e => { this.error = e })
        .finally(() => { this.loading = false })
    },
  },
}
</script>

<style lang="scss">
.ok-confirm-modal {
  position: static;

  .modal-body {
    padding-bottom: 20px;
  }

  .ok-confirm-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: solid 1px #e8e7eb;
    margin-bottom: 13.5px;

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.29;
      color: #6d6a7a;
    }
  }

  .ok-confirm-footer {
    margin-top: 15px;

    .btn {
      font-size: 14px!important;
      font-weight: 600!important;
      line-height: 1.29!important;
    }

    .cancel-btn {
      margin-top: 10px;
    }
  }

  .ok-confirm-content {
    font-size: 14px;
    line-height: 1.25;
    font-weight: 500;
    margin-bottom: 5px;
    color: #aaa8b2;
  }

  .overlay {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
  }

  .ok-modal {
    border-radius: 5px;
    width: 300px;
    z-index: 9999;
    margin: 0 auto;
    padding: 20px 17.5px;
    background-color: var(--white);
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
