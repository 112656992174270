import i18n from '@/libs/i18n'

const BUSINESS_SLUGS = {
  RESTAURANT: 'restaurant',
  SHOP: 'shop',
  SALON: 'salon',
  COFFEE: 'coffee',
  HOSPITAL: 'hospital',
  HOTEL: 'hotel',
  CARS: 'car',
}

export default BUSINESS_SLUGS

export const BUSINESS_FEATURES = {
  CAR_PICKUP: 'pickup',
  COUPONS: 'coupons',
  DELIVERY: 'delivery',
  DISCOUNTS: 'discounts',
  SCHEDULE: 'schedule',
  MODIFIERS: 'modifiers',
  PREPARATION_TIME: 'preparation_time',
  STOCK: 'stock',
  ORDERING: 'ordering',
  SHIPMENT: 'shipment',
  DINE_IN: 'internally',
  TABLES: 'tables',
  ROOMS: 'rooms',
}

export const LABELS_KEYS = ['floor_label', 'table_label']

export const LABELS_OPTIONS = {
  floor_label: [
    { value: 'floor', text: i18n.tc('business-type.floor', 2) },
    { value: 'section', text: i18n.tc('business-type.section', 2) },
    { value: 'department', text: i18n.tc('business-type.department', 2) },
  ],
  table_label: [
    { value: 'table', text: i18n.tc('business-type.table', 2) },
    { value: 'employee', text: i18n.tc('business-type.employee', 2) },
    { value: 'room', text: i18n.tc('business-type.room', 2) },
  ],
}
