import { NavigationGuardNext, Route } from 'vue-router'

import store from '@/store'
import { getAdminPermissionsFromRoute, isSuperAdminHasPermissions } from '@/utils/permissions-helper'

async function checkAdminPermissionsGuard(to: Route, from: Route, next: NavigationGuardNext) {
  if (!store.getters['auth/isAuthenticated']) {
    return next()
  }

  const permissions = getAdminPermissionsFromRoute(to)
  const isSuperAdminHasPermission = store.getters['auth/isSuperAdminHasPermission']

  if (!isSuperAdminHasPermissions(permissions, isSuperAdminHasPermission)) {
    return next({ name: 'unauthorized' })
  }

  return next()
}

export default checkAdminPermissionsGuard
