import get from 'lodash/get'
import flattenDeep from 'lodash/flattenDeep'
import pickBy from 'lodash/pickBy'
// import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import i18n from '@/libs/i18n'

class ErrorHandler {
  static getErrorDetails(error: any) {
    let msg = error ?? i18n.t('errors.something-wrong')
    let title = ''// `${i18n.t('errors.error')}... ${i18n.t('errors.error-occurred')}`
    let errorMessage = ''

    if (error?.message) {
      msg = error.message
    }

    if (typeof error !== 'string') {
      // eslint-disable-next-line no-multi-assign
      msg = errorMessage = get(error, 'response.data.message', get(error, 'response.data.msg', msg))
      msg = typeof msg !== 'string' ? msg?.toString() : msg
    }

    if (error?.response) {
      const errorCode = error.response.status
      if (errorCode === 422) {
        const errorFields = flattenDeep(Object.values(get(error, 'response.data.fields', {}))).concat(flattenDeep(Object.values(get(error, 'response.data.errors', {}))))
        title = i18n.tc('errors.field-invalid', errorFields.length).toString()
        if (errorFields.length === 0) {
          msg = errorMessage || error?.message || error
        } else {
          msg = `<hr class="mx-2"/>${errorFields.join('<hr class="mx-5"/>')}`
        }
      } else if (errorCode === 402) {
        const errors = get(error, 'response.data.errors', [])
        const errorFields = errors.map((e: any) => e?.description ?? e)
        title = msg
        msg = errors.length ? `<hr class="mx-2"/>${errorFields.join('<hr class="mx-5"/>')}` : errorMessage || error?.message || error
      } else if (errorCode === 404) {
        title = error.response?.data?.message || i18n.t('errors.item-not-found').toString()
        msg = ''
      } else if (error.response?.data?.message) {
        msg = error.response?.data?.message
      }
    }
    return { title: title || msg, message: title ? msg : '' }
  }

  static getValidationErrors(validationErrors: any) {
    const parses = uniqBy(Object.keys(pickBy(validationErrors, (error: any) => error?.length)).map(key => ({ key: (key || '').replace(/[0-9]/g, '').trim(), errors: validationErrors[key].map((e: string) => e.replace(/[0-9]/g, '')) })), 'key')
    return parses.reduce((prev, { key, errors }) => `${prev}<p><span class="font-weight-bold">${key}:</span> ${errors.join(', ')}</p>`, '')
  }
}

export default ErrorHandler
