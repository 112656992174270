import StorageService from '@/services/storage-service'
import ShopService from '@/services/shop-service'
import CategoryAPIs from '@/api/store/category'
import ItemAPIs from '@/api/store/item'

export default {
  namespaced: true,
  state: {
    currentBranchId: StorageService.getItem('mnassah_store_account', null),
    branches: [],
    categories: [],
    categoriesFetched: false,
    pricesFetched: false,
    priceLabels: [],
    items: [],
    itemsFetched: false,
    itemsDependencies: false,
    fetchedSettings: {},
  },
  getters: {
    branches: (state: any) => state.branches ?? [],
    priceLabels: (state: any) => state.priceLabels ?? [],
    currentBranchId: (state: any) => state.currentBranchId,
    flatCategories: (state: any) => ShopService.flatCategories(state.categories) ?? [],
    flatItems: (state: any) => ShopService.flatItems(state.items) ?? [],
    currentBranch: (state: any) => state.branches.find((store: any) => store.id === state.currentBranchId) ?? {},
  },
  mutations: {
    SET_BRANCHES(state: any, branches: Array<any>) {
      state.branches = branches
      if (!state.currentBranchId || !branches.find(b => b.id === state.currentBranchId)) {
        state.currentBranchId = branches[0]?.id
      }
    },
    SET_BRANCH(state: any, accountId: number) {
      state.currentBranchId = accountId
    },
    SET_CATEGORIES(state: any, categories: any) {
      state.categories = categories
      state.categoriesFetched = true
    },
    SET_CATEGORIES_FETCHED(state: any, status: any) {
      state.categoriesFetched = status
    },
    UPDATE_PRICES(state: any, newLabel: any) {
      state.priceLabels = [...state.priceLabels, newLabel]
    },
    SET_PRICE_LABELS(state: any, categories: any) {
      state.priceLabels = categories
      state.pricesFetched = true
    },
    SET_ITEMS(state: any, items: any) {
      state.items = items
      state.itemsFetched = true
    },
    SET_FETCHING_SETTINGS(state: any, accountId: number) {
      state.fetchedSettings[accountId] = true
    },
    RESET(state: any) {
      state.fetchedSettings = {}
    },
  },
  actions: {
    SET_STORE(context: any, accountId: number) {
      context.commit('SET_BRANCH', accountId)

      if (!context.rootGetters['auth/impersonate']) {
        StorageService.setItem('mnassah_store_account', accountId)
      }
    },
    async FETCH_CATEGORIES(context: any, force = false) {
      if (context.state.categoriesFetched && !force) return
      // eslint-disable-next-line consistent-return,no-return-await
      await CategoryAPIs.search().then(response => {
        context.commit('SET_CATEGORIES', response)
      })
    },
    async FETCH_ITEM_DEPENDENCIES(context: any, force = false) {
      if (context.state.itemsDependencies && !force) return
      // eslint-disable-next-line consistent-return,no-return-await
      await ItemAPIs.dependencies().then(response => {
        context.commit('SET_PRICE_LABELS', response.prices)
        context.commit('modifier/SET_GROUPS', response.groups, { root: true })
      })
    },
  },
}
