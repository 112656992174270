import NoticesAPIs from '@/api/store/notices'

export default {
  namespaced: true,
  state: {
    noticeFetched: false,
    notice: {},
  },

  getters: {
    noticeFetched: (state: any) => state.noticeFetched,
    notice: (state: any) => state.notice,
  },

  mutations: {
    SET_NOTICE(state: any, payload: any) {
      state.notice = payload
      state.noticeFetched = true
    },
  },
  actions: {
    async FETCH_NOTICE(context: any) {
      if (context.state.noticeFetched) return
      await NoticesAPIs.getNotice().then(res => {
        if (res.error === 0) context.commit('SET_NOTICE', res.notice)
      })
    },
  },
}
