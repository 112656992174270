import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import i18n from '@/libs/i18n'
import OkConfirmPlugin from '@/plugins/ok-confirm-plugin'
import VueLazyload from 'vue-lazyload'
import MomentPlugin from '@/plugins/moment-plugin'
import PortalVue from 'portal-vue'
import GlobalFunctions from '@/libs/general-mixin'
// @ts-ignore
import { ColorPicker, ColorPanel } from 'one-colorpicker'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins

import(/* webpackChunkName: "3rd-libs" */ '@/libs/toastification')
import(/* webpackChunkName: "3rd-libs" */ '@/plugins/vue2-transitions')
import(/* webpackChunkName: "3rd-libs" */ '@/libs/tour')
import(/* webpackChunkName: "3rd-libs" */ '@/plugins/sweet-alert-plugin')
Vue.use(OkConfirmPlugin)
const errorImage = require('@/assets/images/lazy/error.png')

Vue.use(VueLazyload, {
  error: errorImage,
  loading: 'https://placeholder.pics/svg/300/A3D9E9-58E3D2/F8FFE5/%D8%AC%D8%A7%D8%B1%D9%8A%20%D8%AA%D8%AD%D9%85%D9%8A%D9%84%20%D8%A7%D9%84%D8%B5%D9%88%D8%B1%D8%A9',
  attempt: 1,
  observer: true,
})
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.mixin(GlobalFunctions)

Vue.use(ColorPanel)
Vue.use(ColorPicker)
// Composition API
// @ts-ignore
Vue.use(VueCompositionAPI)
Vue.use(MomentPlugin)
Vue.use(PortalVue)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
