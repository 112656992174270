import dayjs from 'dayjs'
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat'
import isBetweenPlugin from 'dayjs/plugin/isBetween'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isoWeek from 'dayjs/plugin/isoWeek'
import relativeTime from 'dayjs/plugin/relativeTime'
import { SERVER_OFFSET, SERVER_TIMEZONE } from '@/constants/config'

const MomentPlugin = {
  install(Vue: any) {
    dayjs.extend(customParseFormatPlugin)
    dayjs.extend(isBetweenPlugin)
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.extend(isoWeek)
    dayjs.extend(relativeTime)

    // eslint-disable-next-line no-param-reassign,no-multi-assign
    Vue.$moment = Vue.prototype.$moment = dayjs
    // eslint-disable-next-line no-param-reassign,no-multi-assign
    Vue.$serverTz = Vue.prototype.$serverTz = SERVER_TIMEZONE
    // eslint-disable-next-line no-param-reassign,no-multi-assign
    Vue.$serverOffset = Vue.prototype.$serverOffset = SERVER_OFFSET
    // eslint-disable-next-line no-param-reassign,no-multi-assign
    Vue.$tz = Vue.prototype.$tz = dayjs.tz.guess()

    // eslint-disable-next-line no-param-reassign,no-multi-assign
    Vue.$removeTimezone = Vue.prototype.$removeTimezone = (date: any) => date?.replace(/[-+]\d+:\d+$/, '') ?? date
  },
}

export default MomentPlugin
