import $http from '@/libs/axios'

class OTPAPIs {
  static async process(data: any) {
    return (await $http.post('otp', data)).data
  }

  static async getSettings() {
    return (await $http.get('otp')).data
  }
}

export default OTPAPIs
