import PlansAPIs from '@/api/admin/plans-api'

export default {
  namespaced: true,
  state: {
    packages: [],
    packagesFetched: false,
    features: [],
    fetched: false,
    featuresFetched: false,
    subscription: {},
  },
  getters: {
    packagesFetched: (state: any) => state.packagesFetched,
    packages: (state: any) => state.packages ?? [],
    features: (state: any) => state.features ?? [],
    subscription: (state: any) => state.subscription ?? {},
    expiresIn: (state: any) => state.subscription?.expires_at ?? '-',
    plan: (state: any) => state.subscription?.plan ?? null,
    price: (state: any) => state.subscription?.price ?? 0,
  },
  mutations: {
    SET_PACKAGES(state: any, packages: any) {
      state.packages = packages
      state.packagesFetched = true
    },
    SET_PRICING(state: any, { packages, features }: any) {
      state.features = features ?? state.features
      state.packages = packages ?? state.packages
      state.featuresFetched = true
      state.packagesFetched = true
    },
    SET_PACKAGES_FETCHED(state: any, payload: boolean) {
      state.features = payload
      state.featuresFetched = true
    },
    SET_SUBSCRIPTION(state: any, payload: boolean) {
      state.subscription = payload
    },
  },
  actions: {
    async FETCH_PACKAGES(context: any) {
      if (context.state.packagesFetched) return
      await PlansAPIs.getPackagesList().then((data: any) => {
        context.commit('SET_PACKAGES', data)
        context.commit('SET_PACKAGES_FETCHED', true)
      }).catch(e => {
        throw e
      })
    },
    async FETCH_PRICING({ state, commit }: any, force: any) {
      if (state.packagesFetched && state.featuresFetched && !force) return true
      const { packages, features } = await PlansAPIs.getSubscriptionDep()
      commit('SET_PRICING', { packages, features })
      return true
    },
    async FETCH_FEATURES({ state, commit }: any, force: any) {
      if (state.featuresFetched && !force) return true
      const features = await PlansAPIs.getFeatures()
      commit('SET_PACKAGE_FEATURES', features)
      return true
    },
  },
}
