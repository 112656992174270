import ASSISTANT_PERMISSIONS from '@/constants/assistant-permissions'
import { BUSINESS_FEATURES } from '@/constants/business-types'
import store from '@/store'
import dashboard from './dashboard'
import items from './items'
import storeRoutes from './store'
import tablesRoutes from './tables'

import categories from './categories'
import promotions from './promotions'

export default [
  {
    title: 'menu.home',
    icon: 'HomeIcon',
    route: 'owner:home',
    all: true,
  }, ...categories, ...items, ...promotions,
  {
    title: 'branches.list',
    icon: 'ListIcon',
    route: 'owner:branches',
  },
  {
    title: 'assistants.title',
    icon: 'UsersIcon',
    route: 'owner:assistants',
  },
  ...tablesRoutes,
  {
    title: 'orders.title',
    icon: 'ListIcon',
    route: 'owner:orders',
    assistantPermissions: [ASSISTANT_PERMISSIONS.ORDERS],
    hide: () => !store.getters['businessTypes/isEnabled'](BUSINESS_FEATURES.ORDERING),
  },
  {
    title: 'cloud.title',
    icon: 'FileIcon',
    route: 'owner:cloud',
  },
  ...storeRoutes, ...dashboard]
