<template>
  <b-row
    style="background-color: #7CC9FF"
    class="bg-gray d-flex align-items-center justify-content-center h-100 w-100 m-0"
  >
    <b-col
      md="5"
      lg="4"
      sm="12"
    >
      <div class="bg-white p-3  rounded w-100">
        <b-link class="brand-logo mb-5 d-flex justify-content-center">
          <mnassah-logo width="300" />
        </b-link>
        <div>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 mt-4 text-center"
          >
            {{ $t('auth.welcome') }}
          </b-card-title>

          <!-- form -->
          <validation-observer ref="loginForm">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="email">{{ $t('fields.email') }}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="email"
                      v-model="username"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge px-1"
                      dir="ltr"
                      name="email"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="MailIcon"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('auth.password') }}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="remember"
                  name="checkbox-1"
                >
                  {{ $t('auth.remember-me') }}
                </b-form-checkbox>
              </b-form-group>
              <!-- checkbox -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="submitting"
                @click="submit"
              >
                <b-spinner
                  v-if="submitting"
                  small
                  class="mr-50"
                />
                {{ $t('auth.login') }}
              </b-button>
            </b-form>
          </validation-observer>
          <error-alert
            v-if="error"
            :error="error"
          />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MnassahLogo from '@core/layouts/components/Logo.vue'
import {
  BLink, BFormGroup, BRow, BCol, BFormInput, BInputGroupAppend, BInputGroup, BCardTitle, BForm, BButton, BFormCheckbox, BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'

export default {
  components: {
    ErrorAlert,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardTitle,
    BForm,
    BButton,
    MnassahLogo,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      remember: true,
      username: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      error: null,
      submitting: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async submit() {
      this.error = null
      const isValid = await this.$refs.loginForm.validate()

      if (!isValid) return

      const credentials = {
        username: this.username,
        password: this.password,
        oneTime: !this.remember,
      }
      this.submitting = true
      this.$store.dispatch('auth/LOGIN', credentials)
        .then(user => {
          if (user) {
            this.$notifySuccess(this.$t('auth.welcome-back-user', [this.$store.getters['auth/user']?.name ?? '']))
            this.$router.push({ name: 'owner:home' })
          } else {
            this.error = this.$t('auth.wrong-data')
            this.$notifyError(this.$t('auth.wrong-data'))
          }
        })
        .catch(err => {
          this.$notifyError(err)
          this.error = err
        }).finally(() => {
          this.submitting = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
