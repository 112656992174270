import ASSISTANT_PERMISSIONS from '@/constants/assistant-permissions'
import store from '@/store'
import { BUSINESS_FEATURES } from '@/constants/business-types'

const items = [
  {
    title: 'store.items',
    icon: 'GridIcon',
    assistantPermissions: [ASSISTANT_PERMISSIONS.MENU, ASSISTANT_PERMISSIONS.MODIFIERS],
    children: [
      {
        title: 'store.products',
        route: 'owner:store-products',
        assistantPermissions: [ASSISTANT_PERMISSIONS.MENU],
      },
      {
        title: 'store.price-labels',
        route: 'owner:price-labels',
        assistantPermissions: [ASSISTANT_PERMISSIONS.MENU],
      },
      {
        title: 'modifiers.modifier-title',
        route: 'owner:modifiers',
        assistantPermissions: [ASSISTANT_PERMISSIONS.MODIFIERS],
        hide: () => !store.getters['businessTypes/isEnabled'](BUSINESS_FEATURES.MODIFIERS),
      },
      {
        title: 'modifiers.group-title',
        route: 'owner:modifier-groups',
        assistantPermissions: [ASSISTANT_PERMISSIONS.MODIFIERS],
        hide: () => !store.getters['businessTypes/isEnabled'](BUSINESS_FEATURES.MODIFIERS),
      },
    ],
  },

]
export default items.map(item => ({ ...item, store: true }))
