export const PACKAGE_PERMISSIONS = {
  DISCOUNT: 'discounts',
  COUPON: 'coupons',
  FEEDBACK: 'surveys',
  KITCHEN: 'order_display_sys',
  QR_CUSTOMIZATION: 'qr_theme',
  IPAD: 'ipad_theme',
  RESERVATION: 'reservation',
  STATISTICS: 'statistics',
  NEWSLETTER: 'newsletter',
  QUEUE: 'queue',
  CRM: 'crm',
  INVENTORY: 'inventory',
}

const FEATURES = {
  BRANCHES: 'branch',
  CLOUD: 'cloud',
  DEVICE: 'device',
  SUB_USERS: 'subusers',
  QR_ITEMS: 'qr_items',
  LANGUAGE: 'language',
  GOOGLE: 'google',
}

export const PACKAGE_FEATURES = {
  INVENTORY: 'inventory',
}
export default FEATURES
