import TablesApi from '@/api/store/tables-api'

export default {
  namespaced: true,
  state: {
    floors: [],
    floorsFetched: false,
    hotelFloors: [],
    hotelFloorsFetched: false,
  },

  getters: {
    floors: (state: any) => state.floors,
    hotelFloors: (state: any) => state.hotelFloors,
    floorsFetched: (state: any) => state.floorsFetched,
    hotelFloorsFetched: (state: any) => state.hotelFloorsFetched,
  },

  mutations: {
    SET_FLOORS(state: any, floors: any) {
      state.floors = floors
      state.floorsFetched = true
    },
    SET_HOTEL_FLOORS(state: any, floors: any) {
      state.hotelFloors = floors
      state.hotelFloorsFetched = true
    },
    UPDATE_FLOORS(state: any, newFloor: any) {
      state.floors = [...state.floors, newFloor]
    },
    UPDATE_HOTEL_FLOORS(state: any, newFloor: any) {
      state.hotelFloors = [...state.hotelFloors, newFloor]
    },
  },
  actions: {
    async FETCH_FLOORS(context: any, force = false) {
      if (context.state.floorsFetched && !force) return
      // eslint-disable-next-line consistent-return,no-return-await
      await TablesApi.listFoors().then((response: any) => {
        context.commit('SET_FLOORS', response ?? [])
      })
    },
    async FETCH_HOTEL_FLOORS(context: any, force = false) {
      if (context.state.hotelFloorsFetched && !force) return
      // eslint-disable-next-line consistent-return,no-return-await
      await TablesApi.listFoors(true).then((response: any) => {
        context.commit('SET_HOTEL_FLOORS', response ?? [])
      })
    },
  },
}
