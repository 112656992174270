import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isDark: 'appConfig/isDark',
      isRTL: 'appConfig/isRTL',
      userLocales: 'auth/locales',
    }),
    selectedLocale() {
      const localesKeys = this.userLocales
      return localesKeys.includes(this.$i18n.locale) ? this.$i18n.locale : localesKeys[0] ?? this.$i18n.locale
    },
    userLocalesWithoutCurrent() {
      const uLocales = [...this.userLocales]
      const priceLocaleIndex = uLocales.findIndex(l => l === this.selectedLocale)
      uLocales.splice(priceLocaleIndex, 1)
      return uLocales
    },
  },
}
